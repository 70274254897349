<template>
  <div id="logoutPage">
    <spin-loader />
  </div>
</template>

<script>
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    SpinLoader,
  },
  data() {
    return {}
  },
  created() {
    setTimeout(() => {
      syslic.authentication.logoff()
      this.$router.push({ name: 'login' })
    }, 2000)
  },
}

</script>

<style lang="scss">
#logoutPage {
  margin-top: 30%;
}
</style>
